const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
// Mật khẩu mới ít nhất 8 kí tự, tối đa 16 kí tự, bao gồm chữ hoa, chữ thường không dấu và số.
const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/;
const regexName = /^[\p{L}0-9]+(([',. -][\p{L}0-9 ])?[\p{L}0-9]*)*$/u;
const regexPhone =
  /(^1[8|9]00([0-9]{4})$)|(^1900([0-9]{6})$)|(^02([0-9]{9})$)|(^0[3|5|7|8|9]([0-9]{8})$)\b/;
const regexUrl = new RegExp(
  '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', // validate fragment locator
  'i',
);
const regexNumber = /^[0-9]*$/;
const regexHotline = /^[0-9]{0,10}$/;

export {
  regexEmail,
  regexPassword,
  regexName,
  regexPhone,
  regexUrl,
  regexNumber,
  regexHotline,
};
